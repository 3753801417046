// Source: app/scripts/config.js


var globalConfig = {
    apiUrl: '',
    loginUrl: '/Account/Login'
}

var dateFormat = {
    mk: 'DD.MM.YYYY'
}

function getApiUrl(url) {
    return globalConfig.apiUrl + url;
};

function getGlobalUrl(url) {
    return getApiUrl(url);
}

function getLoginUrl() {
    return globalConfig.loginUrl;
};

function getDateFormat() {
    return dateFormat.mk;
}