// Source: app/scripts/controllers/ResearchResultsController.js
(function() {
    inspinia.controller("ResearchResultsController",["$uibModal","logger","$scope","$location","mainDataService","$filter", function($uibModal,logger,$scope,$location, service, $filter) {
        var self = this;

        self.showCharts = false;
        var initResearch = function() {
            service.getData("/data/rest/research").then(function(data) {
                self.researches = data.data;
            })
        }

        self.initBaseline = function() {
            service.getData("/data/rest/measurement/research/"+self.research.id+"/by_attributes/baseline?attr1="+self.attribute1+"&attr2="+self.attribute2)
                .then(function(data) {
                    self.baselineData = data.data;
                    self.lineCO2Data["series"] = [self.baselineData[self.attribute1]["CO2eq"],self.baselineData[self.attribute2]["CO2eq"]];
                    self.lineExpensesData["series"] = [self.baselineData[self.attribute1]["Expenses"],self.baselineData[self.attribute2]["Expenses"]];
                    self.lineMarginData["series"] = [self.baselineData[self.attribute1]["Marg_cost"],self.baselineData[self.attribute2]["Marg_cost"]];
                    self.lineJobsData["series"] = [self.baselineData[self.attribute1]["Jobs"],self.baselineData[self.attribute2]["Jobs"]];
                })
        }
        self.attributes = [];
        var onMeasurements = function(data) {
            var set = new Set();

            _.forEach(data, function(d) {
                set.add(d.attribute);
            })
            self.attributes = Array.from(set);
        }



        self.onResearchChange = function() {
            service.getData("/data/rest/measurement/research/"+self.research.id).then(function(data) {
                self.measurements = data.data;
                onMeasurements(self.measurements);
            })
        }

        self.setResearchId = function(id) {
            service.getData("/data/rest/research/"+id).then(function(data) {
                self.research = data.data;
                self.onResearchChange();
            })

        }

        self.setSubtitle = function(subtitle) {
            console.log(subtitle)
            self.subtitle = subtitle;
        }

        self.loadMeasurements = function() {


                service.getData("/data/rest/measurement/research/" + self.research.id + "/by_attributes?attr1=" + self.attribute1 + "&attr2=" + self.attribute2).then(function (data) {
                    self.data = data.data;
                    angular.forEach(self.yAxeValues, function (yAxe) {
                        self.prepareData(yAxe);
                    })

                })
        }
        self.loadAttrData = function(code,callback) {
            service.getData("/data/rest/indicator/code/" + code ).then(function (data) {
                callback(data.data);

            })
        }
        self.attr1 = [];
        self.attr2 = [];
        self.yAxeValues = [];
        self.onAttribute1Change = function() {
            self.showCharts=false;
            self.attr1 = [];
            _.forEach(self.measurements, function(m) {
                if (m.attribute===self.attribute1) {
                    self.attr1[m.yAxe]=m;
                }

            });
            if (self.attribute1!==undefined && self.attribute2!==undefined) {
                self.yAxeValues = Object.keys(self.attr1);
                self.initBaseline();
                self.loadMeasurements();

            }
            self.loadAttrData(self.attribute1, function(data) {
                console.log(data);
                if (data.length>0) {
                    self.attr1Data = data[0];
                } else {
                    self.attr1Data = undefined;
                }

            });
        }
        self.onAttribute2Change = function() {
            self.showCharts=false;
            self.attr2 = [];

            _.forEach(self.measurements, function(m) {
                if (m.attribute===self.attribute2) {
                    self.attr2[m.yAxe]=m;
                }

            });
            if (self.attribute1!==undefined && self.attribute2!==undefined) {
                self.yAxeValues = Object.keys(self.attr2);
                self.loadMeasurements();
                self.initBaseline();
            }
            self.loadAttrData(self.attribute2, function(data) {
                console.log(data);
                if (data.length>0) {
                    self.attr2Data = data[0];
                }else {
                    self.attr2Data = undefined;
                }
            });
        }

        self.preparedData = [];

        self.prepareData = function(yAxe) {
            self.preparedData[yAxe] = new Object();

            var barVisTypes = ["Expenses","CO2eq","Marg_cost"];
            var pieVisTypes = ["Expenses","CO2eq","Marg_cost"];
            var pictoVisTypes = ["Jobs"];

            var data = self.data[yAxe];

            var barData = {};
            angular.forEach(barVisTypes, function(visType) {
                var bar = {
                    labels: [$filter('translate')(self.attribute1), $filter('translate')(self.attribute2)],
                    series: [
                        [parseFloat(data[self.attribute1][visType].value).toFixed(2)],
                        [parseFloat(data[self.attribute2][visType].value).toFixed(2)]
                    ]
                };
                barData[visType] = bar;
            });
            self.preparedData[yAxe]['bar'] = barData;

            var co2Attr1Data = [];
            var co2Attr2Data = [];
            var indexedData = [];
            var pieData = {};
            angular.forEach(pieVisTypes, function(visType) {
                co2Attr1Data.push(parseInt(data[self.attribute1][visType].value));
                co2Attr2Data.push(parseInt(data[self.attribute2][visType].value));
                var sum = Math.abs(parseInt(data[self.attribute1][visType].value))+Math.abs(parseInt(data[self.attribute2][visType].value));
                var val1 = Math.abs(parseInt(parseInt(data[self.attribute1][visType].value)))/sum * 100;
                var val2 = Math.abs(parseInt(parseInt(data[self.attribute2][visType].value)))/sum * 100;
                var pie = {
                    labels: [parseFloat(val1).toFixed(1)+"%", parseFloat(val2).toFixed(1)+"%"],
                    series: [
                        val1.toFixed(2),val2.toFixed(2)

                    ]
                };
                pieData[visType] = pie;

            })
            self.preparedData[yAxe]['pie'] = pieData;


            var pictoData = {};
            angular.forEach(pictoVisTypes, function(visType) {
                var picto = {left:{},right:{}};
                if (data[self.attribute1][visType] == undefined) {
                    picto['left'].visible = false;
                } else {
                    var left = data[self.attribute1][visType].value;
                    var max = 756;
                    var value = parseInt(parseInt(left)*100/max);

                    picto.left={visible:true,value:value,real_value:left};
                }
                if (data[self.attribute2][visType] == undefined) {
                    picto['right'].visible = false;
                }else {
                    var right = data[self.attribute2][visType].value;
                    var max = 756;
                    var value = parseInt(parseInt(right)*100/max);
                    picto.right={visible:true,value:value,real_value:right};
                }
                pictoData[visType] = picto;
            });
            self.preparedData[yAxe]['picto'] = pictoData;
            self.showCharts = true;
            console.log(self.preparedData);
        }

        self.init = function() {
            initResearch();
        }

        self.init();

        self.barData = {
            labels: ['28_PUB_LIGHT','11_SOL_THERM'],
            series: [
                [86],
                [83]
            ]};

        self.bar2Data = {
            labels: ['28_PUB_LIGHT','11_SOL_THERM'],
            series: [
                [-32],
                [-29]
            ]};

        self.barCO2Data = {
            labels: ['28_PUB_LIGHT','11_SOL_THERM'],
            series: [
                [14076],
                [699]
            ]};

        self.barCO2Data = {
            labels: ['1_LOSSES_ELC','10_BITOLA_TO'],
            series: [
                [14076],
                [699]
            ]};


        self.barOptions = {
            seriesBarDistance: 180,
            axisX: {
                //labelInterpolationFnc: function(value) {
                //    return value.split(/\s+/).map(function(word) {
                //        return word[0];
                //    }).join('');
                //}
            },
            plugins: [
                Chartist.plugins.tooltip()
            ]
        };
        self.barROptions = [
            ['screen and (max-width: 640px)', {
                seriesBarDistance: 100,
                axisX: {
                    labelInterpolationFnc: function (value) {
                        return value[0];
                    }
                }
            }]
        ];

        self.pieROptions  = [
            ['screen and (min-width: 640px)', {
                chartPadding: 10,
                labelOffset: 50,
                labelDirection: 'explode',
                labelInterpolationFnc: function(value) {
                    return value;
                }
            }],
            ['screen and (min-width: 1024px)', {
                labelOffset: 50,
                chartPadding: 10
            }]
        ];

        self.pieData = {
            series: [ 50.8,49.2],
            labels: ['50.8%','49.2%']
        };

        self.pieCO2Data = {
            series: [ 96,4],
            labels: ['96%','4%']
        }


        self.pieOptions = {
            donut: true,
            donutWidth: 30,
            startAngle: 0,
            labelOffset: -35,
            showLabel: true,

            plugins: [
                Chartist.plugins.fillDonut({
                    items: [
                        {
                        content: '<img src="/images/icons/marg-costs.png" style="width:70px" class="img-responsive">',
                        position: 'center',
                        offsetY : -40,
                        offsetX: 0
                        }
                        /*,
                        {
                            content: $filter("translate")("visualizations.relative"),
                            position: 'bottom',
                            offsetY : -20,
                        }*/
                        ]
                }),
                Chartist.plugins.tooltip()

            ]
        };
        self.pieExpensesOptions = {
            donut: true,
            donutWidth: 30,
            startAngle: 0,
            labelOffset: -35,
            showLabel: true,

            plugins: [
                Chartist.plugins.fillDonut({
                    items: [
                        {
                            content: '<img src="/images/icons/money-bag.png" style="width:70px" class="img-responsive">',
                            position: 'center',
                            offsetY : -40,
                            offsetX: 0
                        }
                        /*
                        {
                            content: $filter("translate")("visualizations.relative"),
                            position: 'bottom',
                            offsetY : -20,
                        }
                        */
                    ]
                }),
                Chartist.plugins.tooltip()
            ]
        };



        self.pieCO2TreeOptions = {
            donut: true,
            donutWidth: 30,
            startAngle: 0,
            labelOffset: -35,
            showLabel: true,

            plugins: [
                Chartist.plugins.fillDonut({
                    items: [
                        {
                            content: '<img src="/images/icons/tree.png" style="width:70px" class="img-responsive">',
                            position: 'center',
                            offsetY : -40,
                            offsetX: 0
                        }
                        /*,
                        {
                            content: $filter("translate")("visualizations.relative"),
                            position: 'bottom',
                            offsetY : -20,
                        }*/
                    ]
                }),
                Chartist.plugins.tooltip()

            ]
        };




        /* line chart */

        self.lineCO2Data = {
            labels: ['2020','2025','2030','2035'],
            series: [
                [12, 9, 7, 8],
                [5, 12, 7, 16]
            ]
        }

        self.lineExpensesData = {
            labels: ['2020','2025','2030','2035']
        }

        self.lineMarginData = {
            labels: ['2020','2025','2030','2035']
        }

        self.lineJobsData = {
            labels: ['2025','2030','2035']
        }

    }]);
})();