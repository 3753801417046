// Source: app/scripts/controllers/SubscriberController.js
(function() {
    inspinia.controller("SubscriberController",["mainDataService", function(service) {
        var self = this;
        self.subscriber = new Object();
        self.subscribed=false;
        self.subscribe = function() {
            service.postData("/data/rest/subscriber",self.subscriber).then(function(data) {
                if (data.status===200) {
                    self.subscribed=true;
                }
            })
        }
    }])
})();