// Source: app/scripts/controllers/TwitterSearchDashboard.js
(function() {
    inspinia.controller("TwitterSearchDashboard",["mainDataService", function(service) {
        var self = this;
        self.init = function() {
            service.getData("/data/rest/social/twitter_share_post/grouped").then(function(data) {
                self.data = {"labels":[], "series":[[]]};
                console.log(data.data);
                _.forEach(data.data, function(value,key) {
                    console.log(value);
                    var dateFrom =  moment(moment().day("Monday").week(key).toDate()).format("DD.MM");
                    var dateTo = moment(moment().day("Saturday").week(key).toDate()).format("DD.MM YYYY");
                    self.data.labels.push(dateFrom + " - "+dateTo);
                    self.data.series[0].push(value);
                })
                //self.data = {"labels":["2020","2025","2030","2035"],"series":[[18165,64729,85099,105724],[2279,12022,18438,25648]]};
            });
        }
        self.init();

        self.barOptions = {
            seriesBarDistance: 180,
            axisX: {
                //labelInterpolationFnc: function(value) {
                //    return value.split(/\s+/).map(function(word) {
                //        return word[0];
                //    }).join('');
                //}
            },
            plugins: [
                Chartist.plugins.tooltip()
            ]
        };

        self.lineOptions = {
        }
    }]);

})();