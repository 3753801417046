// Source: app/scripts/controllers/traffic/CityController.js
(function() {
    inspinia.controller('cityController', ['$scope', '$http', function ($scope, $http) {

        $scope.autocomplete = function () {
            var city = $scope.city;
            $http.get('/data/rest/cities', {params: {name: city}}).then(function (response) {
                $scope.cities = [{"name":"London","country":"CA","featureCode":"PPL","adminCode":"08","population":346765,"lat":42.98339,"lon":-81.23304},{"name":"Londonderry County Borough","country":"GB","featureCode":"PPLX","adminCode":"NIR","population":87153,"lat":54.99721,"lon":-7.30917},{"name":"London","country":"GB","featureCode":"PPLC","adminCode":"ENG","population":7556900,"lat":51.50853,"lon":-0.12574},{"name":"London Village","country":"KI","featureCode":"PPL","adminCode":"02","population":1829,"lat":1.98487,"lon":-157.47502},{"name":"London","country":"US","featureCode":"PPL","adminCode":"AR","population":1039,"lat":35.32897,"lon":-93.25296},{"name":"London","country":"US","featureCode":"PPLA2","adminCode":"KY","population":7993,"lat":37.12898,"lon":-84.08326},{"name":"Londontowne","country":"US","featureCode":"PPL","adminCode":"MD","population":8018,"lat":38.93345,"lon":-76.54941},{"name":"London","country":"US","featureCode":"PPLA2","adminCode":"OH","population":9904,"lat":39.88645,"lon":-83.44825},{"name":"Londonderry","country":"US","featureCode":"PPL","adminCode":"NH","population":11037,"lat":42.86509,"lon":-71.37395},{"name":"London","country":"US","featureCode":"PPL","adminCode":"CA","population":1869,"lat":36.47606,"lon":-119.44318}];
                //console.log(response.data);
            });
        }
    }])
})();
(function() {
    inspinia.service('mapService', function () {

        this.getTrafficLayer = function (selector, lat, lng) {
            var map = new google.maps.Map(document.getElementById(selector), {
                zoom: 13,
                center: {lat: lat ? lat : 34.2343, lng: lng ? lng : 21.2324}
            });

            this.map = map;
            var trafficLayer = new google.maps.TrafficLayer();
            trafficLayer.setMap(map);
        }
    })
})();