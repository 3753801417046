// Source: app/scripts/interceptor.js
(function() {
        inspinia.factory('LanguageHttpInterceptors', function ($q, $location, $rootScope, $translate, $cookies) {

        return {

            'request': function (config) {

                // Add token in each request header
                var lang = $cookies.get("lang");
                console.log("COOKIE "+lang);
                if (config.params) {
                    lang =  config.params.lang;
                }
                config.headers['Accept-Language'] = lang;
                config.headers['Language'] = lang;

                return config || $q.when(config);
            }
        };
    });
})();
