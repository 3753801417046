// Source: app/scripts/services/mainDataService.js
(function () {
    inspinia.service('mainDataService', ['$q', '$http', 'logger', function ($q, $http, logger) {

        this.postData = function (url, data) {
            return (data !== undefined ? $http.post(getApiUrl(url),data ) : $http.post(getApiUrl(url)))
                .then(function (res) {
                    if (res.status === 200 && res.data.status) {
                        if (res.data.message !== undefined && res.data.message !== null) {
                            logger.logSuccess(res.data.Message);
                        }
                    } else {
                        logger.logError('Server unavailable. Try again later!');
                    }
                    return res;
                }).catch(function () {
                    logger.logError('Server unavailable. Try again later!');
                });
        };

        this.getData = function (url, data) {
            return (data !== undefined ? $http.get(getApiUrl(url), { params: data }) : $http.get(getApiUrl(url)))
                .then(function (res) {
                    if (res.status === 200) {
                        if (res.data !== undefined && res.data !== null) {
                            //logger.logSuccess(res.data.Message);
                        }
                    } else {
                        logger.logError(res.data);
                    }
                    return res;
                }).catch(function () {
                    logger.logError('Server unavailable. Try again later!');
                });
        };
    }]);
}());