// Source: app/scripts/app.js
(function () {
    window.inspinia = angular.module('inspinia', [
        'ui.router',
        'ui.bootstrap' ,
        'ngCookies',
        'pascalprecht.translate',
        'toastr',
        'ui.bootstrap',
        'ngSanitize',
        'ngFileUpload',
        'ngAnimate',
        'angular.filter',
        'ui.sortable',
        'uiGmapgoogle-maps',
        'mgcrea.bootstrap.affix',
        'wu.masonry',
        'infinite-scroll',
        'chart.js',
        'angular-svg-round-progressbar',
        '720kb.socialshare',
        'ngMeta',
        'chartistAngularDirective'

    ]);

    inspinia.filter('trusted', ['$sce', function ($sce) {
        return function(url) {
            return $sce.trustAsResourceUrl(url);
        };
    }]);

    inspinia.value('backendServerUrl', window.location.hostname);

    inspinia.config([
        '$stateProvider','$urlRouterProvider','$httpProvider','$translateProvider','uiGmapGoogleMapApiProvider',
        function ( $stateProvider, $urlRouterProvider,$httpProvider,$translateProvider,uiGmapGoogleMapApiProvider) {
            //$locationProvider.html5Mode(true);
            //$httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';


            $translateProvider.useCookieStorage();
            $translateProvider.useUrlLoader("/i18n/translations");

            /*$translateProvider.useStaticFilesLoader({
                prefix: 'i18n/',
                suffix: '.json'
            });*/

            $translateProvider.preferredLanguage("mk");



            $urlRouterProvider.otherwise("/index/main");

            $httpProvider.interceptors.push('LanguageHttpInterceptors');

            uiGmapGoogleMapApiProvider.configure({
                key: 'AIzaSyBFigRUXprc2GBM6LOClRtR9fy660DUpzQ',
                libraries: 'weather,geometry,visualization'
               // v: '3.20', //defaults to latest 3.X anyhow
              //  libraries: 'weather,geometry,visualization'
            });

            $stateProvider

                .state('index', {
                    abstract: true,
                    url: "/index",
                    templateUrl: "/app/views/common/content.html",
                })
                .state('index.main', {
                    url: "/main",
                    templateUrl: "/app/views/main.html",
                    controller:"HomeController",
                    controllerAs:"main",
                    data: {  pageTitle: 'Biodiveristy Items' }
                })
                .state('index.nearby', {
                    url: "/nearby",
                    templateUrl: "/app/views/nearby.html",
                    controller:"NearbyController",
                    controllerAs:"main",
                    data: {  pageTitle: 'Biodiversity nearby items' }
                })
                .state('index.visits', {
                    url: "/visits",
                    templateUrl: "/app/views/main.html",
                    controller:"VisitsController",
                    controllerAs:"main",
                    data: {  pageTitle: 'Biodiversity most visited items' }
                })
                .state('index.popularityIndex', {
                    url: "/popularityIndex",
                    templateUrl: "/app/views/main.html",
                    controller:"PopularItemsController",
                    controllerAs:"main",
                    data: {  pageTitle: 'Biodiversity nearby items' }
                })
                .state('index.myitems', {
                    url: "/myitems",
                    templateUrl: "/app/views/myitems.html",
                    controller:"MyItemsController",
                    controllerAs:"main",
                    data: {  pageTitle: 'My Items' }
                })
                .state('index.favourites', {
                    url: "/favourites",
                    templateUrl: "/app/views/favourites.html",
                    controller:"MyFavouritesController",
                    controllerAs:"main",
                    data: {  pageTitle: 'My Favourite Bio Items' }
                })
                .state("index.visited", {
                    url: "/visited",
                    controller:"VisitsController",
                    controllerAs:"main",
                    templateUrl:"/app/views/visits.html"
                })
                .state('index.mainId', {
                    url: "/main/:id",
                    templateUrl: "/app/views/main.html",
                    controller:"MainCtrl",
                    controllerAs:"main",
                    data: {  pageTitle: 'Преглед на повици' }
                })
                .state('index.minor', {
                    url: "/minor",
                    templateUrl: "/app/views/minor.html",
                    data: { pageTitle: 'Преглед на настани' }
                })
                .state('index.login', {
                    url: "/login",
                    templateUrl: "/app/views/login.html",
                    data: { pageTitle: 'Login' }
                })
                .state('index.profile', {
                    url: "/profile",
                    templateUrl: "/app/views/user/user.view.html",
                    controller:"User.View.Controller",
                    controllerAs:"main",
                    data: { pageTitle: 'User profile' }
                })
                .state('index.profileEdit', {
                    url: "/profile/edit",
                    templateUrl: "/app/views/user/user.edit.html",
                    controller:"User.Edit.Controller",
                    controllerAs:"main",
                    data: { pageTitle: 'Edit User profile' }
                })
                .state("index.regions", {
                    url: "/regions",
                    controller:"Regions.Controller",
                    controllerAs:"main",
                    templateUrl: "/app/views/regions.html",
                    data: { pageTitle: 'Protected Regions' }
                })
                .state("index.bikes", {
                    url: "/bikes",
                    controller:"Bikes.Controller",
                    controllerAs:"main",
                    templateUrl: "/app/views/main.html",
                    data: { pageTitle: 'Bikes Regions' }
                })
                .state("index.gallery", {
                    url: "/gallery",
                    controller:"Gallery.Controller",
                    controllerAs:"main",
                    templateUrl:"/app/views/gallery.html"
                })
                .state("index.itemView", {
                    url: "/item/{itemId}",
                    controller:"item.View",
                    controllerAs:"main",
                    templateUrl:"/app/views/item/item.View.html"
                })
                .state("index.addPhoto", {
                    url: "/item/{itemId}/photo/add",
                    controller:"Photo.Add.Controller",
                    controllerAs:"main",
                    templateUrl:"/app/views/photo/photo.New.html"
                })
                .state("index.search", {
                    url: "/search/{term}",
                    controller:"Search.View.Controller",
                    controllerAs:"main",
                    templateUrl:"/app/views/search.html"
                })

               ;


        }
    ]);


    inspinia.run([
        '$rootScope','$state','$translate','mainDataService','ngMeta','$location','$cookies', function ($rootScope, $state,$translate,model, ngMeta,$location, $cookies) {
            $rootScope.$state = $state;
            $rootScope.authenticated = false;

            function getParameterByName(name) {
                name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
                var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                    results = regex.exec(location.search);
                return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
            }
            console.log("TRANSLATE"+$translate.proposedLanguage());
            var lang=$cookies.get("lang");
            $translate.use(lang);

            $rootScope.init = function() {


                model.getData("/rest/user").then(function(data) {
                    if (data!==undefined) {
                        if (data.data.username !== undefined && data.data.username !== null) {
                            $rootScope.user = data.data;
                        }

                    }

                });
            }

            /*
            if (getParameterByName("lang")!==undefined && getParameterByName("lang")!=null) {
                if (getParameterByName("lang")==='mk_MK'){
                    $rootScope.lang='mk';
                    $translate.use('mk');
                } else {
                    $rootScope.lang='en';
                    $translate.use('en');
                }
            }

            $rootScope.changeLanguage = function(lang) {
                $translate.use(key);
            }
            */
            $rootScope.init();
            ngMeta.init();
            //$rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
            //    if (oldUrl !== newUrl) {
            //        $window.location.href = newUrl;
            //    }
            //});
        }
    ]);
})();