// Source: app/scripts/controllers/TranslationController.js
(function () {
    inspinia.controller("TranslationController", ["$translate", "$location","$rootScope","$window","$q", function ($translate, $location, $rootScope, $window, $q) {
        var self = this;
        self.changeLanguage = function (lang) {
            $translate.use(lang);
            $rootScope.languageKey = lang;
            $q.defer();
            if (lang==='mk')
                window.location.replace("/?lang=mk_MK");
            else
                window.location.replace("/?lang=en_US");
        }

    }]);
}());